import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import Badge from '@shared/ui/badges/Badge';
import { BodySmall, Title } from '@src/components/styled/Typography';
import { ReactComponent as CheckmarkIconComponent } from '@icons/wolfkit-light/checkmark-light.svg';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { defineInfoCellStyles, renderValue } from './utils';
const PlanListContainer = styled(ContainerRow)((props) => ({
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const defineActiveStyles = ({ theme, active, isPlanDescription, }) => {
    if (active) {
        return {
            backgroundColor: theme.customColors.shades.primaryLight,
            boxShadow: theme.customColors.switcher.boxShadowActive,
            border: 'unset',
        };
    }
    if (isPlanDescription) {
        return {
            backgroundColor: '#F8F9FA',
            boxShadow: 'unset',
            border: 'unset',
        };
    }
    return {
        backgroundColor: 'transparent',
        boxShadow: 'unset',
        border: 'unset',
    };
};
const InfoContainer = styled(PlanListContainer)(props => (Object.assign({ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: props.flex, borderRadius: 12, maxWidth: 320, gap: 0 }, defineActiveStyles({
    theme: props.theme,
    active: props.active,
    isPlanDescription: props.isPlanDescription,
}))));
const PlanTitle = styled(Title)(props => ({
    fontSize: 30,
    lineHeight: '36px',
    textAlign: 'center',
    fontWeight: 600,
    position: 'relative',
    marginBottom: props.theme.spacing_sizes.xs * 0.75,
}));
const PlanDescription = styled(BodySmall)(props => ({
    height: 40,
    fontSize: 16,
    color: props.theme.palette.text.secondary,
    padding: `0 ${props.theme.spacing_sizes.xs * 4.5}px`,
    lineHeight: '19px',
    textAlign: 'center',
}));
const ButtonContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
    boxSizing: 'border-box',
    alignItems: 'center',
    marginBottom: props.theme.spacing_sizes.l,
}));
const CheckmarkIcon = styled(CheckmarkIconComponent)(props => ({
    path: {
        stroke: props.theme.palette.text.disabled,
        strokeWidth: 0,
    },
}));
const PlanTextContainer = styled.div(props => ({
    padding: `${props.theme.spacing_sizes.l}px 0`,
    width: '100%',
    height: 80,
    borderBottom: '1px solid #EFF1F3',
}));
const BadgeContainer = styled.span(props => ({
    position: 'absolute',
    top: 4,
    color: props.theme.palette.warning.main,
    backgroundColor: props.theme.palette.warning.light,
    padding: `${props.theme.spacing_sizes.s}px ${props.theme.spacing_sizes.xs * 1.25}px`,
    fontSize: 14,
    marginLeft: 14,
    borderRadius: 6,
    boxSizing: 'border-box',
    lineHeight: '20px',
}));
const InfoCell = styled(ContainerColumn, {
    shouldForwardProp: propName => propName !== 'align' &&
        propName !== 'hasBorder' && propName !== 'isPlanDescription' &&
        propName !== 'isFirstElement',
})(props => (Object.assign({ alignItems: props.align || 'unset', height: 'auto', borderBottom: props.hasBorder ? `1px solid ${props.theme.palette.primary.light}` : 'unset', boxSizing: 'border-box' }, defineInfoCellStyles({
    theme: props.theme,
    isFirstElement: props.isFirstElement,
    isPlanDescription: props.isPlanDescription,
}))));
const FeeCell = styled(InfoCell)(props => ({
    fontSize: `${props.theme.baseSize * 4}px`,
    fontWeight: 600,
    padding: `${props.theme.spacing_sizes.l}px 0px`,
}));
const RecommendedBadge = () => {
    const { t } = useTranslation();
    return (_jsx(BadgeContainer, { children: t('settings.tabs.subscription.subscription_plan.recommended_badge') }));
};
const LimitsNumber = styled.span(() => ({
    fontWeight: 600,
    lineHeight: '19px',
}));
const LimitsText = styled.span(() => ({
    fontSize: 12,
}));
const SubscriptionPlanInfo = ({ plan, flex = 1, isDescriptionContainer = undefined, onSelect = undefined, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const getFee = () => {
        if (plan.isFree)
            return t('settings.tabs.subscription.subscription_plan.free');
        if (plan.currency && plan.fee)
            return `$ ${plan.fee || ''} / month`;
        return '';
    };
    const handleSelect = () => {
        if (onSelect) {
            onSelect(plan.id);
        }
    };
    const getButtons = () => {
        if (isDescriptionContainer) {
            if (plan.isSelected) {
                return (_jsx(ButtonContainer, { children: _jsx(Button, { variant: 'filled', color: 'primary', size: 'medium', startIcon: CheckmarkIcon, children: t('settings.tabs.subscription.current_plan') }) }));
            }
            if (!plan.isAvailable) {
                return (_jsx(Badge, { variant: 'tinted', style: {
                        backgroundColor: theme.palette.primary.light,
                        fontSize: 14,
                    }, children: t('settings.tabs.subscription.subscription_plan.coming_soon') }));
            }
            return (_jsx(ButtonContainer, { children: _jsx(Button, { variant: 'tinted', color: 'primary', size: 'medium', onClick: handleSelect, children: t('settings.tabs.subscription.subscription_plan.select_plan_btn') }) }));
        }
        return '';
    };
    return (_jsxs(InfoContainer, { flex: flex, active: plan.isSelected, isPlanDescription: isDescriptionContainer, children: [_jsxs(PlanTextContainer, { children: [_jsx(PlanTitle, { children: plan.isRecommended ? (_jsxs(_Fragment, { children: [plan.title, _jsx(RecommendedBadge, {})] })) : plan.title }), _jsx(PlanDescription, { children: plan.description })] }), _jsx(InfoCell, { align: isDescriptionContainer ? 'center' : 'start', isFirstElement: true, isPlanDescription: isDescriptionContainer, hasBorder: true, children: isDescriptionContainer ? (_jsxs(_Fragment, { children: [_jsx(LimitsNumber, { children: plan.options.limits }), _jsx(LimitsText, { children: t('settings.tabs.subscription.subscription_plan.per_exchange') })] })) :
                    plan.options.limits }, 'limits'), _jsx(InfoCell, { align: isDescriptionContainer ? 'center' : 'start', isPlanDescription: isDescriptionContainer, hasBorder: true, children: renderValue(plan.options.spotCopy) }, 'spot-copy'), _jsx(InfoCell, { align: isDescriptionContainer ? 'center' : 'start', isPlanDescription: isDescriptionContainer, hasBorder: true, children: renderValue(plan.options.margin) }, 'margin'), _jsx(InfoCell, { align: isDescriptionContainer ? 'center' : 'start', isPlanDescription: isDescriptionContainer, hasBorder: true, children: renderValue(plan.options.futures) }, 'futures'), _jsx(InfoCell, { align: isDescriptionContainer ? 'center' : 'start', isPlanDescription: isDescriptionContainer, hasBorder: true, children: plan.options.support }, 'support'), _jsx(FeeCell, { align: isDescriptionContainer ? 'center' : 'start', children: getFee() }), getButtons()] }));
};
export default SubscriptionPlanInfo;
